:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._btw-t-space-0{border-top-width:var(--t-space-0);}
:root ._brw-t-space-0{border-right-width:var(--t-space-0);}
:root ._bbw-t-space-0--53{border-bottom-width:var(--t-space-0--5);}
:root ._blw-t-space-0{border-left-width:var(--t-space-0);}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._bts-solid{border-top-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._jc-center{justify-content:center;}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._bg-color1{background-color:var(--color1);}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._ai-center{align-items:center;}
@media (max-width: 800px){:root:root:root:root ._pt-_sm_t-space-2--53{padding-top:var(--t-space-2--5);}}
@media (max-width: 800px){:root:root:root:root ._pb-_sm_t-space-2--53{padding-bottom:var(--t-space-2--5);}}
@media (max-width: 800px){:root:root:root:root ._dsp-_sm_none{display:none;}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._dsp-_gtSm_none{display:none;}}
:root ._gap-t-space-5{gap:var(--t-space-5);}
:root ._w-10037{width:100%;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-0--5px{border-bottom-width:0.5px;}
:root ._blw-0px{border-left-width:0px;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._btc-strokeSecon3075972{border-top-color:var(--strokeSecondary);}
:root ._brc-strokeSecon3075972{border-right-color:var(--strokeSecondary);}
:root ._blc-strokeSecon3075972{border-left-color:var(--strokeSecondary);}
:root ._transform-translateY-1736186894{transform:translateY(-0.5px);}
:root ._maw-800px{max-width:800px;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._pr-t-space-2{padding-right:var(--t-space-2);}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._miw-230px{min-width:230px;}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112923{line-height:var(--f-lineHeight-4);}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}