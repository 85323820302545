:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._cur-pointer{cursor:pointer;}
:root:root  ._bg-0active-backgroundP3496915:active{background-color:var(--backgroundPress) !important;}
:root ._fs-1{flex-shrink:1;}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._fg-1{flex-grow:1;}
:root ._btc-strokeSecon3075972{border-top-color:var(--strokeSecondary);}
:root ._brc-strokeSecon3075972{border-right-color:var(--strokeSecondary);}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._blc-strokeSecon3075972{border-left-color:var(--strokeSecondary);}
:root ._bg-transparent{background-color:transparent;}
:root ._btw-0px{border-top-width:0px;}
:root ._bts-solid{border-top-style:solid;}
:root ._btw-0--5px{border-top-width:0.5px;}
:root ._bbw-0--5px{border-bottom-width:0.5px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._h-t-size-4{height:var(--t-size-4);}
:root ._gap-t-space-5{gap:var(--t-space-5);}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._w-10037{width:100%;}