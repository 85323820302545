:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._btlr-100000px{border-top-left-radius:100000px;}
:root ._btrr-100000px{border-top-right-radius:100000px;}
:root ._bbrr-100000px{border-bottom-right-radius:100000px;}
:root ._bblr-100000px{border-bottom-left-radius:100000px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._h-5px{height:5px;}
:root ._w-5px{width:5px;}
:root ._zi-2{z-index:2;}
:root ._bg-color13{background-color:var(--color13);}