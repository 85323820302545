:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._w-10037{width:100%;}
:root ._btlr-t-radius-8{border-top-left-radius:var(--t-radius-8);}
:root ._btrr-t-radius-8{border-top-right-radius:var(--t-radius-8);}
:root ._bbrr-t-radius-8{border-bottom-right-radius:var(--t-radius-8);}
:root ._bblr-t-radius-8{border-bottom-left-radius:var(--t-radius-8);}
:root ._pt-t-space-7{padding-top:var(--t-space-7);}
:root ._pr-t-space-7{padding-right:var(--t-space-7);}
:root ._pb-t-space-7{padding-bottom:var(--t-space-7);}
:root ._pl-t-space-7{padding-left:var(--t-space-7);}
:root ._bg-backgroundS881234945{background-color:var(--backgroundSecondary);}
:root ._btc-strokeSecon3075972{border-top-color:var(--strokeSecondary);}
:root ._brc-strokeSecon3075972{border-right-color:var(--strokeSecondary);}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._blc-strokeSecon3075972{border-left-color:var(--strokeSecondary);}
:root ._btw-0--5px{border-top-width:0.5px;}
:root ._brw-0--5px{border-right-width:0.5px;}
:root ._bbw-0--5px{border-bottom-width:0.5px;}
:root ._blw-0--5px{border-left-width:0.5px;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-1{gap:var(--t-space-1);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bxsh-0px5px5pxva26674076{box-shadow:0px 5px 5px var(--shadowColor);}
@media (max-width: 800px){:root:root:root:root ._w-_sm_10037{width:100%;}}