:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pb-t-space-6{padding-bottom:var(--t-space-6);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._bbw-0--5px{border-bottom-width:0.5px;}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._gap-t-space-2--53{gap:var(--t-space-2--5);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-4{gap:var(--t-space-4);}